import { Fragment, useEffect, useState, Suspense } from "react";
import { BrowserRouter as Switch, Route, useHistory, Link } from "react-router-dom";
// import { I18nextProvider } from 'react-i18next';

import i18next from "./i18n/init";
import { useStore } from "./hooks-store/store";
// import MainNavigation from './components/layout/MainNavigation';

// bookremeet related
import AuthCheck from "./components/auth/AuthCheck";
import Layout from "./components/layout/Layout";
import Loader from './components/Loader/Loader';
import NewMeetupForm from "./pages/new-meetup/NewMeetupForm";
import EditMeetupForm from "./pages/edit-meetup/EditMeetupForm";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import UserInfo from "./pages/user-info/UserInfo";
import UserRoomPage from "./pages/user-room/UserRoomPage";

// stripe related
import Register from "./pages/subscription/Register";
import Account from "./pages/subscription/account/Account";
import Cancel from "./pages/subscription/cancel/Cancel";
import Prices from "./components/subscription/prices/Prices";
import PricesTrialUpgrade from "./pages/subscription/prices-trial-upgrade/PricesTrialUpgrade";
import Subscribe from "./pages/subscription/subscribe/Subscribe";
import SubscribeTrialUpgrade from "./pages/subscription/subscribe-trial-upgrade/SubscribeTrialUpgrade";
import ChangePlan from "./pages/subscription/change-plan/ChangePlan";
import SubscriptionLayout from "./components/layout/SubscriptionLayout";
import TrialInformation from "./pages/trial-information/TrialInformation";
import Notification from "./components/ui/Notification";
import Contact from "./pages/contact/Contact";

import { getAuthInfo, logoutHandler } from "./utils/user";
import { 
  getCustomerDataSubscriptions, 
  getCurrentSubscription, 
  fetchPrices 
} from './utils/stripe';
import "./App.css";

////
////

// //for local dev
// export const BACKEND_URL = process.env.REACT_APP_BACKEND_DEV_URL;
// export const authLoginPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const authSignupPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// //// stripe test-mode priceIds
// //// export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_TEST_BASIC;
// //// export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_TEST_MINI;
// export const weplPremiumPriceId = process.env.REACT_APP_SUBPRICE_TEST_WEPLPREMIUM;

// export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_BASIC;
// export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_MINI;
// // export const weplPremiumPriceId = process.env.REACT_APP_SUBPRICE_WEPLPREMIUM;

// export const SUBSCRIPTION_BACKEND_URL = process.env.REACT_APP_SUBSCRIPTION_BACKEND_DEV_URL;
// export const roomPageUrl = process.env.REACT_APP_EDUMEET_URL;
// export const weplPageUrl = process.env.REACT_APP_EDUMEETWEPL_URL;


// // for test deploy
export const BACKEND_URL = process.env.REACT_APP_BACKEND_TEST_URL;
export const authLoginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const authSignupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_BASIC;
export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_MINI;
export const weplPremiumPriceId = process.env.REACT_APP_SUBPRICE_WEPLPREMIUM;
export const SUBSCRIPTION_BACKEND_URL = process.env.REACT_APP_SUBSCRIPTION_BACKEND_TEST_URL;
export const roomPageUrl = process.env.REACT_APP_EDUMEET_URL;
export const weplPageUrl = process.env.REACT_APP_EDUMEETWEPL_URL;


// // for deploy
// export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// export const authLoginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const authSignupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_BASIC;
// export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_MINI;
// export const weplPremiumPriceId = process.env.REACT_APP_SUBPRICE_WEPLPREMIUM;
// export const SUBSCRIPTION_BACKEND_URL = process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL;
// export const roomPageUrl = process.env.REACT_APP_EDUMEET_URL;
// export const weplPageUrl = process.env.REACT_APP_EDUMEETWEPL_URL;





//// console.log(BACKEND_URL, authLoginPageLink, authSignupPageLink);
//// export const userLoginEmail = localStorage.getItem('email') ? localStorage.getItem('email') : '';

////
////
// console.log(i18next);

function App(props) {
  // console.log("app.js-props", props);
  const history = useHistory();

  const [store, dispatch] = useStore();
  console.log('store in app.js', store);

  const [isLoading, setIsLoading] = useState(false);

  const userLoginEmail = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";
  const { i18n, notification } = store;


  useEffect(() => {
    // console.log(i18next);
    dispatch("SET_I18NEXT", i18next);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getAuthInfo()
      .then((result) => {
        console.log('get authinfo result', result);

        const token = localStorage.getItem("token");
        const expiryDate = localStorage.getItem("expiryDate");
        const userId = localStorage.getItem('userId');
        const email = localStorage.getItem('email');
        if (!token || !expiryDate || !userId || !email) {
          logoutHandler();
          return;
        }

        // console.log('date', new Date().getTime() - new Date(expiryDate).getTime())
        const tokenRemainNum = new Date(expiryDate).getTime() - new Date().getTime();
        console.log('tokenRemainNum', tokenRemainNum);
        
        if (tokenRemainNum <= 0) {
          dispatch("SET_AUTHSTATE", false);
          logoutHandler();
          return;
        }

        dispatch("SET_AUTHSTATE", true);
        // window.location.replace('/');

        // const userPath = `/?customer-email=${userLoginEmail}`
        // history.replace(userPath);
        setIsLoading(false);
        history.replace('/');
        // window.location.reload();

      })
      .catch((err) => {
        console.log('get authinfo error', err);

        err.message = "Login failed ...";
        
        setIsLoading(false);
        dispatch("SET_AUTHSTATE", false);
        logoutHandler();
      });
  }, []);


  useEffect(() => {
    const lsCustomerEmail = localStorage.getItem('customerEmail');
    
    if (userLoginEmail && lsCustomerEmail && store.roomConfig) {

      const token = localStorage.getItem('token');
    
      getCustomerDataSubscriptions(token, userLoginEmail)
        .then(result => {
          console.log('get sub result', result);

          if (result.data.length > 0) {
            const subs = result.data;
            // dispatch('SET_SUBSCRIPTIONS', subs);





            const weplSubs = subs.filter(sub => {
              return sub.metadata.service === 'REMEET-WePL';
            })

            dispatch('SET_SUBSCRIPTIONS', weplSubs);


          }

        })
        .catch(err => {
          console.log('get sub error', err);
        });
    }
  }, [userLoginEmail, store.roomConfig]);


  let routes;
  routes = (
    <div>
      <Switch>
        <Route path="/privacy-policy">
          <Layout>
            <PrivacyPolicy />
          </Layout>
        </Route>
        <Route path="/terms-of-use">
          <Layout>
            <TermsOfUse />
          </Layout>
        </Route>

        <Route path="/trial-information">
          <Layout>
            <TrialInformation />
          </Layout>
        </Route>
        <Route path="/contact">
          {/* <Layout> */}
            <Contact />
          {/* </Layout> */}
        </Route>

        {!userLoginEmail && (
          <Route path="/">
            <Layout>
            </Layout>
          </Route>
        )}

        {userLoginEmail && (
          <div>
            <Route exact path="/">
              <Layout>
                <NewMeetupForm />
              </Layout>
            </Route>
            <Route path="/edit-room">
              <Layout>
                <EditMeetupForm />
              </Layout>
            </Route>

            <Route path="/user-info">
              <Layout>
                <UserInfo />
              </Layout>
            </Route>
            <Route path="/user-room">
              <Layout
                hideSubNavigation={true}
              >
                <UserRoomPage />
              </Layout>
            </Route>

            {/* subscription related */}
            <Route path="/subscription">
                <Register />
            </Route>
            <Route path="/subscription/prices">
              <SubscriptionLayout>
                <Prices />
              </SubscriptionLayout>
            </Route>
            <Route path="/subscription/prices-trial-upgrade">
              <SubscriptionLayout>
                <PricesTrialUpgrade />
              </SubscriptionLayout>
            </Route>
            <Route path="/subscription/subscribe">
              <SubscriptionLayout>
                <Subscribe />
              </SubscriptionLayout>
            </Route>
            <Route path="/subscription/change-plan">
              <SubscriptionLayout>
                <ChangePlan />
              </SubscriptionLayout>
            </Route>
            <Route path="/subscription/account">
              <SubscriptionLayout>
                <Account />
              </SubscriptionLayout>
            </Route>
            <Route path="/subscription/cancel">
              <SubscriptionLayout>
                <Cancel />
              </SubscriptionLayout>
            </Route>
            <Route path="/subscription/subscribe-trial-upgrade">
              <SubscriptionLayout>
                <SubscribeTrialUpgrade />
              </SubscriptionLayout>
            </Route>
          </div>
        )}
      </Switch>
    </div>
  );



  // if (isLoading) {
  //   return (<div><Loader /></div>);
  // }

  return (
    <Fragment>
      <Suspense fallback={null}>
        {/* <I18nextProvider i18n={i18n}> */}

        {notification.status && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
        {routes}
        {/* </I18nextProvider> */}
        
        <AuthCheck />
      </Suspense>
    </Fragment>
  );
}

export default App;
